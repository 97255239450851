var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __require = /* @__PURE__ */ ((x) => typeof require !== "undefined" ? require : typeof Proxy !== "undefined" ? new Proxy(x, {
  get: (a, b) => (typeof require !== "undefined" ? require : a)[b]
}) : x)(function(x) {
  if (typeof require !== "undefined")
    return require.apply(this, arguments);
  throw Error('Dynamic require of "' + x + '" is not supported');
});
var __commonJS = (cb, mod) => function __require2() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// package.json
var require_package = __commonJS({
  "package.json"(exports, module) {
    module.exports = {
      scripts: {
        lint: "run -T eslint . --ext .ts,.tsx",
        clean: "rm -rf ./dist",
        dev: "parcel public/test.html",
        docs: "typedoc src/index.ts",
        build: "run -T tsup src/index.ts --format cjs,esm --dts && run -T publint",
        prepublish: "npm run clean && npm run build",
        test: "run -T jest --config ./jestconfig.js",
        "test:watch": "run -T jest --config ./jestconfig.js --watchAll"
      },
      name: "@giphy/js-fetch-api",
      version: "5.2.0",
      description: "Javascript API to fetch gifs and stickers from the GIPHY API.",
      homepage: "https://github.com/Giphy/giphy-js/tree/master/packages/fetch-api",
      main: "dist/index.cjs",
      types: "dist/index.d.ts",
      module: "dist/index.js",
      type: "module",
      sideEffects: false,
      exports: {
        ".": {
          types: "./dist/index.d.ts",
          import: "./dist/index.js",
          require: "./dist/index.cjs"
        },
        "./package.json": "./package.json"
      },
      files: [
        "dist/",
        "src/**/*"
      ],
      license: "MIT",
      publishConfig: {
        access: "public"
      },
      dependencies: {
        "@giphy/js-types": "*",
        "@giphy/js-util": "*",
        qs: "^6.9.4"
      },
      devDependencies: {
        "@types/qs": "^6.9.4",
        "jest-fetch-mock": "^3.0.3",
        "parcel-bundler": "latest",
        typedoc: "^0.20.37",
        "typedoc-thunder-theme": "^0.0.3",
        typescript: "^5.0.4"
      }
    };
  }
});

// src/index.ts
import { appendGiphySDKRequestHeader, getGiphySDKRequestHeaders } from "@giphy/js-util";

// src/api.ts
import { getPingbackId } from "@giphy/js-util";
import qs from "qs";

// src/normalize/gif.ts
var BOOL_PROPS = [
  "is_anonymous",
  "is_community",
  "is_featured",
  "is_hidden",
  "is_indexable",
  "is_preserve_size",
  "is_realtime",
  "is_removed",
  "is_sticker",
  "is_dynamic"
  // not finalized, and not adding to Gif type until type RFC is finished
];
var USER_BOOL_PROPS = ["suppress_chrome", "is_public", "is_verified"];
var makeBool = (obj) => (prop) => obj[prop] = !!obj[prop];
var getTag = (tag) => typeof tag === "string" ? tag : tag.text;
var normalize = (gif) => {
  const newGif = __spreadValues({}, gif);
  newGif.id = String(newGif.id);
  newGif.tags = (newGif.tags || []).map(getTag);
  BOOL_PROPS.forEach(makeBool(newGif));
  Object.keys(newGif.images || {}).forEach((name) => {
    const img = newGif.images[name];
    img.width = parseInt(img.width);
    img.height = parseInt(img.height);
  });
  const { user } = newGif;
  if (user) {
    const newUser = __spreadValues({}, user);
    USER_BOOL_PROPS.forEach(makeBool(newUser));
    newGif.user = newUser;
  }
  return newGif;
};
var normalizeGif = (result) => {
  result.data = normalize(result.data);
  return result;
};
var normalizeGifs = (result) => {
  result.data = result.data.map((gif) => normalize(gif));
  return result;
};

// src/constants.ts
var gl = (typeof window !== "undefined" ? window : global) || {};
var serverUrl = gl.GIPHY_API_URL || "https://api.giphy.com/v1/";
var setServerUrl = (url) => {
  serverUrl = url;
};

// src/fetch-error.ts
var FetchError = class extends Error {
  constructor(message, url, status = 0, statusText = "") {
    super(message);
    this.url = url;
    this.status = status;
    this.statusText = statusText;
  }
};
var fetch_error_default = FetchError;

// src/request.ts
var ERROR_PREFIX = `@giphy/js-fetch-api: `;
var DEFAULT_ERROR = "Error fetching";
var identity = (i) => i;
var requestMap = {};
var maxLife = 6e4;
var errorMaxLife = 6e3;
var purgeCache = () => {
  const now = Date.now();
  Object.keys(requestMap).forEach((key) => {
    const ttl = requestMap[key].isError ? errorMaxLife : maxLife;
    if (now - requestMap[key].ts >= ttl) {
      delete requestMap[key];
    }
  });
};
function request(url, options = {}) {
  const { apiVersion = 1, noCache = false, normalizer = identity } = options;
  const serverUrl_ = serverUrl.replace(/\/v\d+\/$/, `/v${apiVersion}/`);
  purgeCache();
  if (!requestMap[url] || noCache) {
    const fullUrl = `${serverUrl_}${url}`;
    const makeRequest = () => __async(this, null, function* () {
      var _a2;
      let fetchError;
      try {
        const response = yield fetch(fullUrl, {
          method: "get"
        });
        if (response.ok) {
          const result = yield response.json();
          if (!((_a2 = result.meta) == null ? void 0 : _a2.response_id)) {
            throw { message: `synthetic response` };
          } else {
            return normalizer(result);
          }
        } else {
          let message = DEFAULT_ERROR;
          try {
            const result = yield response.json();
            if (result.message)
              message = result.message;
          } catch (_) {
          }
          if (requestMap[url]) {
            requestMap[url].isError = true;
          }
          fetchError = new fetch_error_default(
            `${ERROR_PREFIX}${message}`,
            fullUrl,
            response.status,
            response.statusText
          );
        }
      } catch (unexpectedError) {
        fetchError = new fetch_error_default(unexpectedError.message, fullUrl);
        if (requestMap[url]) {
          requestMap[url].isError = true;
        }
      }
      throw fetchError;
    });
    requestMap[url] = { request: makeRequest(), ts: Date.now() };
  }
  return requestMap[url].request;
}
var request_default = request;

// src/api.ts
var getType = (options) => options && options.type ? options.type : "gifs";
var GiphyFetch = class {
  constructor(apiKey, qsParams = {}) {
    /**
     * @hidden
     */
    this.getQS = (options = {}) => {
      return qs.stringify(__spreadValues(__spreadProps(__spreadValues({}, options), { api_key: this.apiKey, pingback_id: getPingbackId() }), this.qsParams));
    };
    this.apiKey = apiKey;
    this.qsParams = qsParams;
  }
  /**
   * A list of categories
   *
   * @param {CategoriesOptions} [options]
   * @returns {Promise<CategoriesResult>}
   */
  categories(options) {
    return request_default(`gifs/categories?${this.getQS(options)}`);
  }
  /**
   * Get a single gif by a id
   * @param {string} id
   * @returns {Promise<GifsResult>}
   **/
  gif(id) {
    return request_default(`gifs/${id}?${this.getQS()}`, { normalizer: normalizeGif });
  }
  gifs(arg1, arg2) {
    if (Array.isArray(arg1)) {
      return request_default(`gifs?${this.getQS({ ids: arg1.join(",") })}`, {
        normalizer: normalizeGifs
      });
    }
    return request_default(`gifs/categories/${arg1}/${arg2}?${this.getQS()}`, {
      normalizer: normalizeGifs
    });
  }
  emoji(options) {
    return request_default(`emoji?${this.getQS(options)}`, { normalizer: normalizeGifs });
  }
  /**
   * Returns a list of all the default emoji variations
   *
   * @param {PaginationOptions} options
   * @returns {Promise<GifsResult>}
   **/
  emojiDefaultVariations(options) {
    return request_default(`emoji?${this.getQS(options)}`, {
      apiVersion: 2,
      normalizer: normalizeGifs
    });
  }
  /**
   * Returns a list of gifs representing all the variations for the emoji
   *
   * @param {string} id
   * @returns {Promise<NonPaginatedGifsResult>}
   **/
  emojiVariations(id) {
    return request_default(`emoji/${id}/variations?${this.getQS()}`, {
      apiVersion: 2,
      normalizer: normalizeGifs
    });
  }
  animate(text, options = {}) {
    const qsParams = this.getQS(__spreadProps(__spreadValues({}, options), { m: text }));
    return request_default(`text/animate?${qsParams}`, { normalizer: normalizeGifs });
  }
  /**
   * @param term: string The term you're searching for
   * @param options: SearchOptions
   * @returns {Promise<GifsResult>}
   **/
  search(term, options = {}) {
    const q = options.channel ? `@${options.channel} ${term}` : term;
    let excludeDynamicResults;
    if (options.type === "text") {
      excludeDynamicResults = true;
    }
    const qsParams = this.getQS(__spreadProps(__spreadValues({ rating: "pg-13" }, options), { q, excludeDynamicResults }));
    return request_default(`${getType(options)}/search?${qsParams}`, { normalizer: normalizeGifs });
  }
  /**
   * Get a list of subcategories
   * @param {string} category
   * @param {SubcategoriesOptions} options
   * @returns {Promise<CategoriesResult>}
   */
  subcategories(category, options) {
    return request_default(`gifs/categories/${category}?${this.getQS(options)}`);
  }
  /**
   * Get trending gifs
   *
   * @param {TrendingOptions} options
   * @returns {Promise<GifsResult>}
   */
  trending(options = {}) {
    return request_default(`${getType(options)}/trending?${this.getQS(__spreadValues({ rating: "pg-13" }, options))}`, {
      normalizer: normalizeGifs
    });
  }
  /**
   * Get a random gif
   * @param {RandomOptions} options
   * @returns {Promise<GifResult>}
   **/
  random(options) {
    return request_default(`${getType(options)}/random?${this.getQS(__spreadValues({ rating: "pg-13" }, options))}`, {
      noCache: true,
      normalizer: normalizeGif
    });
  }
  /**
   * Get related gifs by a id
   * @param {string} id
   * @param {SubcategoriesOptions} options
   * @returns {Promise<GifsResult>}
   **/
  related(id, options = {}) {
    const { type = "gifs" } = options;
    return request_default(
      `${type}/related?${this.getQS(__spreadValues({
        gif_id: id,
        rating: "pg-13"
      }, options))}`,
      { normalizer: normalizeGifs }
    );
  }
  /**
   * Search for channels based on a term
   * @param {string} term
   * @param options: SearchOptions
   * @returns {Promise<ChannelsResult>}
   **/
  channels(term, options = {}) {
    return request_default(
      `channels/search?${this.getQS(__spreadValues({ q: term, rating: "pg-13" }, options))}`
    );
  }
};
var api_default = GiphyFetch;

// src/paginator.ts
var gifPaginator = (fetchGifs, initialGifs = []) => {
  const gifs = [...initialGifs];
  const gifIds = initialGifs.map((g) => g.id);
  let offset = initialGifs.length;
  let isDoneFetching = false;
  return () => __async(void 0, null, function* () {
    if (isDoneFetching) {
      return gifs;
    }
    const result = yield fetchGifs(offset);
    const { pagination, data: newGifs } = result;
    offset = pagination.count + pagination.offset;
    isDoneFetching = offset === pagination.total_count;
    newGifs.forEach((gif) => {
      const { id } = gif;
      if (!gifIds.includes(id)) {
        gifs.push(gif);
        gifIds.push(id);
      }
    });
    return [...gifs];
  });
};

// src/index.ts
var _a;
if (typeof __require !== "undefined") {
  const { version } = require_package();
  if (!((_a = getGiphySDKRequestHeaders()) == null ? void 0 : _a.get(`X-GIPHY-SDK-NAME`))) {
    appendGiphySDKRequestHeader(`X-GIPHY-SDK-NAME`, "FetchAPI");
    appendGiphySDKRequestHeader(`X-GIPHY-SDK-VERSION`, version);
  }
}
export {
  fetch_error_default as FetchError,
  api_default as GiphyFetch,
  gifPaginator,
  request_default as request,
  serverUrl,
  setServerUrl
};
