var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

// src/log.ts
var LogLevel = /* @__PURE__ */ ((LogLevel2) => {
  LogLevel2[LogLevel2["DEBUG"] = 0] = "DEBUG";
  LogLevel2[LogLevel2["INFO"] = 1] = "INFO";
  LogLevel2[LogLevel2["WARN"] = 2] = "WARN";
  LogLevel2[LogLevel2["ERROR"] = 3] = "ERROR";
  return LogLevel2;
})(LogLevel || {});
var Logger = {
  ENABLED: typeof window !== "undefined" && typeof location !== "undefined" && location.search.indexOf("giphy-debug") !== -1,
  LEVEL: 0,
  PREFIX: "GiphyJS",
  debug: (...msg) => {
    if (Logger.ENABLED && Logger.LEVEL <= 0 /* DEBUG */) {
      console.debug(Logger.PREFIX, ...msg);
    }
  },
  info: (...msg) => {
    if (Logger.ENABLED && Logger.LEVEL <= 1 /* INFO */) {
      console.info(Logger.PREFIX, ...msg);
    }
  },
  warn: (...msg) => {
    if (Logger.ENABLED && Logger.LEVEL <= 2 /* WARN */) {
      console.warn(Logger.PREFIX, ...msg);
    }
  },
  error: (...msg) => {
    if (Logger.ENABLED && Logger.LEVEL <= 3 /* ERROR */) {
      console.error(Logger.PREFIX, ...msg);
    }
  }
};

// src/bestfit.ts
var closestArea = (width, height, renditions) => {
  let currentBest = Infinity;
  let result;
  renditions.forEach((rendition) => {
    const widthPercentage = rendition.width / width;
    const heightPercentage = rendition.height / height;
    const areaPercentage = widthPercentage * heightPercentage;
    const testBest = Math.abs(1 - areaPercentage);
    if (testBest < currentBest) {
      currentBest = testBest;
      result = rendition;
    }
  });
  return result;
};
var SCALE_UP_MAX_PIXELS = 50;
var setRenditionScaleUpMaxPixels = (pixels) => {
  Logger.debug(`@giphy/js-util set rendition selection scale up max pixels to ${pixels}`);
  SCALE_UP_MAX_PIXELS = pixels;
};
function bestfit(renditions, width, height, scaleUpMaxPixels = SCALE_UP_MAX_PIXELS) {
  let [largestRendition] = renditions;
  const testRenditions = renditions.filter((rendition) => {
    if (rendition.width * rendition.height > largestRendition.width * largestRendition.height) {
      largestRendition = rendition;
    }
    return width - rendition.width <= scaleUpMaxPixels && height - rendition.height <= scaleUpMaxPixels;
  });
  if (testRenditions.length === 0) {
    return largestRendition;
  }
  return closestArea(width, height, testRenditions);
}
var bestfit_default = bestfit;

// src/collections.ts
function mapValues(object, mapFn) {
  if (Array.isArray(object)) {
    throw `This map is just for objects, just use array.map for arrays`;
  }
  return Object.keys(object).reduce((result, key) => {
    result[key] = mapFn(object[key], key);
    return result;
  }, {});
}
function forEach(object, mapFn) {
  if (Array.isArray(object)) {
    throw `This map is just for objects, just use array.forEach for arrays`;
  }
  return Object.keys(object).forEach((key) => {
    mapFn(object[key], key);
  });
}
function take(arr, count = 0) {
  return arr.slice(0, count);
}
function without(arr, values) {
  return arr.filter((val) => values.indexOf(val) === -1);
}
function pick(object, pick2) {
  const res = {};
  pick2.forEach((key) => {
    if (object[key] !== void 0) {
      res[key] = object[key];
    }
  });
  return res;
}

// src/get-client-rect-from-el.ts
var getClientRect = (el) => {
  let left = 0;
  let top = 0;
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  do {
    left += el.offsetLeft;
    top += el.offsetTop;
    el = el.offsetParent;
  } while (el);
  const result = {
    left,
    top,
    width,
    height,
    right: left + width,
    bottom: top + height,
    x: left,
    y: top
  };
  return __spreadProps(__spreadValues({}, result), { toJSON: () => JSON.stringify(result) });
};
var get_client_rect_from_el_default = getClientRect;

// src/get-pingback-id.ts
import { v4 as uuid } from "uuid";
var pingbackId = "";
var idLength = 16;
var noUUIDRandom = () => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < idLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
var getPingbackId = () => {
  if (!pingbackId) {
    try {
      pingbackId = sessionStorage.getItem("giphyPingbackId");
    } catch (_) {
    }
    if (!pingbackId) {
      const hexTime = (/* @__PURE__ */ new Date()).getTime().toString(16);
      try {
        pingbackId = `${hexTime}${uuid().replace(/-/g, "")}`.substring(0, idLength);
      } catch (error) {
        pingbackId = noUUIDRandom();
      }
      try {
        sessionStorage.setItem("giphyPingbackId", pingbackId);
      } catch (_) {
      }
    }
  }
  return pingbackId;
};
var get_pingback_id_default = getPingbackId;

// src/webp-check.ts
var SUPPORTS_WEBP = null;
var checkIfWebP = new Promise((resolve) => {
  if (typeof Image === "undefined") {
    resolve(false);
  }
  const webp = new Image();
  webp.onload = () => {
    SUPPORTS_WEBP = true;
    resolve(SUPPORTS_WEBP);
  };
  webp.onerror = () => {
    SUPPORTS_WEBP = false;
    resolve(SUPPORTS_WEBP);
  };
  webp.src = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
});

// src/gif-utils.ts
var getSpecificRendition = ({ images, is_sticker: isSticker }, renditionLabel, isStill = false, useVideo = false) => {
  if (!images || !renditionLabel)
    return "";
  isStill = isStill && !useVideo;
  const rendition = images[`${renditionLabel}${isStill ? "_still" : ""}`];
  if (rendition) {
    if (isSticker || isStill) {
      return rendition.url;
    }
    const webP = SUPPORTS_WEBP && rendition.webp;
    return useVideo ? rendition.mp4 : webP || rendition.url;
  }
  return "";
};
var getBestVideo = (video, width, height) => {
  let assets = video == null ? void 0 : video.assets;
  if (assets) {
    assets = __spreadValues({}, assets);
    delete assets.source;
    const filteredAssets = Object.values(assets).sort((a, b) => a.width - b.width);
    return bestfit_default(filteredAssets, width, height);
  }
};
var getRenditions = (type, images, video) => type === "video" && video && video.previews && !Object.keys(images).length ? video.previews : images;
var getBestRendition = (images, gifWidth, gifHeight, scaleUpMaxPixels) => {
  const checkRenditions = pick(images, [
    "original",
    "fixed_width",
    "fixed_height",
    "fixed_width_small",
    "fixed_height_small"
  ]);
  const testImages = Object.entries(checkRenditions).map(([renditionName, val]) => __spreadValues({
    renditionName
  }, val));
  return bestfit_default(testImages, gifWidth, gifHeight, scaleUpMaxPixels);
};
var getBestRenditionUrl = ({ images, video, type }, gifWidth, gifHeight, options = { isStill: false, useVideo: false }) => {
  if (!gifWidth || !gifHeight || !images)
    return "";
  const { useVideo, isStill, scaleUpMaxPixels } = options;
  const renditions = getRenditions(type, images, video);
  const { renditionName } = getBestRendition(renditions, gifWidth, gifHeight, scaleUpMaxPixels);
  const key = `${renditionName}${isStill && !useVideo ? "_still" : ""}`;
  const rendition = renditions[key];
  const match = useVideo ? rendition.mp4 : SUPPORTS_WEBP && rendition.webp ? rendition.webp : rendition.url;
  return match || "";
};
var getGifHeight = ({ images }, gifWidth) => {
  const { fixed_width } = images;
  if (fixed_width) {
    const { width, height } = fixed_width;
    const aspectRatio = width / height;
    return Math.round(gifWidth / aspectRatio);
  }
  return 0;
};
var getGifWidth = ({ images }, gifHeight) => {
  const { fixed_width } = images;
  if (fixed_width) {
    const { width, height } = fixed_width;
    const aspectRatio = width / height;
    return Math.round(gifHeight * aspectRatio);
  }
  return 0;
};
var getAltText = ({ alt_text, user, tags = [], is_sticker = false, title = "" }) => {
  if (alt_text) {
    return alt_text;
  }
  if (title) {
    return title;
  }
  const username = user && user.username || "";
  const filteredTags = take(without(tags, ["transparent"]), username ? 4 : 5);
  return `${username ? `${username} ` : ``}${filteredTags.join(" ")} ${is_sticker ? "Sticker" : "GIF"}`;
};

// src/sdk-headers.ts
var gl = (typeof window !== "undefined" ? window : global) || {};
gl._GIPHY_SDK_HEADERS_ = gl._GIPHY_SDK_HEADERS_ || (gl.Headers ? new gl.Headers({
  "X-GIPHY-SDK-PLATFORM": "web"
}) : void 0);
var getGiphySDKRequestHeaders = () => gl._GIPHY_SDK_HEADERS_;
var appendGiphySDKRequestHeader = (key, value) => {
  var _a;
  return (_a = getGiphySDKRequestHeaders()) == null ? void 0 : _a.set(key, value);
};
var appendGiphySDKRequestParam = (key, value) => {
  var _a;
  return (_a = getGiphySDKRequestHeaders()) == null ? void 0 : _a.set(key, value);
};
export {
  LogLevel,
  Logger,
  appendGiphySDKRequestHeader,
  appendGiphySDKRequestParam,
  bestfit_default as bestfit,
  checkIfWebP,
  forEach,
  getAltText,
  getBestRendition,
  getBestRenditionUrl,
  getBestVideo,
  get_client_rect_from_el_default as getClientRect,
  getGifHeight,
  getGifWidth,
  getGiphySDKRequestHeaders,
  get_pingback_id_default as getPingbackId,
  getSpecificRendition,
  mapValues,
  pick,
  setRenditionScaleUpMaxPixels,
  take,
  without
};
