var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};

// src/merge-attributes.ts
import { pick } from "@giphy/js-util";
var mergeAttribute = (attributes, newAttributes, key) => {
  const result1 = pick(attributes, [key]);
  const result2 = pick(newAttributes, [key]);
  if (result1[key] && result2[key]) {
    return __spreadValues(__spreadValues(__spreadValues({}, attributes), newAttributes), { [key]: result1[key] + ", " + result2[key] });
  }
  return __spreadValues(__spreadValues({}, attributes), newAttributes);
};
var merge_attributes_default = mergeAttribute;

// src/pingback.ts
import { getPingbackId, Logger as Logger2 } from "@giphy/js-util";
import { debounce } from "throttle-debounce";

// src/global.ts
var global_default = (typeof window !== "undefined" ? window : global) || {};

// src/send-pingback.ts
import { getGiphySDKRequestHeaders, Logger } from "@giphy/js-util";
var _a;
var environment = ((_a = global_default) == null ? void 0 : _a.GIPHY_PINGBACK_URL) || "https://pingback.giphy.com";
var pingBackUrl = `${environment}/v2/pingback?apikey=l0HlIwPWyBBUDAUgM`;
var sendPingback = (events) => {
  const headers = getGiphySDKRequestHeaders();
  headers == null ? void 0 : headers.set("Content-Type", "application/json");
  Logger.debug(`Pingback session`, events);
  if (!events.length) {
    return new Promise((resolve) => resolve());
  }
  return fetch(pingBackUrl, {
    method: "POST",
    body: JSON.stringify({ events }),
    headers
  }).catch((error) => {
    Logger.warn(`pingbacks failing to post ${error}`);
  });
};

// src/pingback.ts
var queuedPingbackEvents = [];
global_default.giphyRandomId = getPingbackId();
var loggedInUserId = "";
function sendPingbacks() {
  const sendEvents = [...queuedPingbackEvents];
  queuedPingbackEvents = [];
  sendPingback(sendEvents);
}
var debouncedPingbackEvent = debounce(1e3, sendPingbacks);
var _a2, _b;
(_b = (_a2 = global_default).addEventListener) == null ? void 0 : _b.call(_a2, "beforeunload", sendPingbacks);
var pingback = ({
  userId,
  eventType,
  actionType,
  attributes,
  queueEvents = true,
  analyticsResponsePayload
}) => {
  loggedInUserId = userId ? String(userId) : loggedInUserId;
  const newEvent = {
    ts: Date.now(),
    attributes,
    action_type: actionType,
    user_id: getPingbackId(),
    analytics_response_payload: analyticsResponsePayload
  };
  if (loggedInUserId) {
    newEvent.logged_in_user_id = loggedInUserId;
  }
  if (newEvent.analytics_response_payload) {
    newEvent.analytics_response_payload = `${newEvent.analytics_response_payload}${Logger2.ENABLED ? "&mode=verification" : ""}`;
  }
  if (eventType) {
    newEvent.event_type = eventType;
  }
  queuedPingbackEvents.push(newEvent);
  queueEvents ? debouncedPingbackEvent() : sendPingbacks();
};
var pingback_default = pingback;
export {
  merge_attributes_default as mergeAttributes,
  pingback_default as pingback
};
