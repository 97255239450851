// src/index.ts
var giphyBlack = "#121212";
var giphyDarkestGrey = "#212121";
var giphyDarkGrey = "#2e2e2e";
var giphyDarkCharcoal = "#3e3e3e";
var giphyCharcoal = "#4a4a4a";
var giphyLightCharcoal = "#5c5c5c";
var giphyLightGrey = "#a6a6a6";
var giphyLightestGrey = "#d8d8d8";
var giphyWhiteSmoke = "#ececec";
var giphyWhite = "#ffffff";
var giphyBlue = "#00ccff";
var giphyGreen = "#00ff99";
var giphyPurple = "#9933ff";
var giphyRed = "#ff6666";
var giphyYellow = "#fff35c";
var giphyAqua = "#00e6cc";
var giphyLightBlue = "#3191ff";
var giphyIndigo = "#6157ff";
var giphyPink = "#e646b6";
var facebookColor = "#3894fc";
var twitterColor = "#00ccff";
var pinterestColor = "#e54cb5";
var tumblrColor = "#529ecc";
var instagramColor = "#c23c8d";
var redditColor = "#fc6669";
var smsColor = "#00ff99";
var errorColor = giphyRed;
var deleteColor = giphyRed;
var primaryCTA = giphyIndigo;
var primaryCTADisabled = "#241F74";
var secondaryCTA = giphyCharcoal;
var dimColor = `rgba(0, 0, 0, 0.8)`;
var gifOverlayColor = `rgba(0, 0, 0, 0.4)`;
export {
  deleteColor,
  dimColor,
  errorColor,
  facebookColor,
  gifOverlayColor,
  giphyAqua,
  giphyBlack,
  giphyBlue,
  giphyCharcoal,
  giphyDarkCharcoal,
  giphyDarkGrey,
  giphyDarkestGrey,
  giphyGreen,
  giphyIndigo,
  giphyLightBlue,
  giphyLightCharcoal,
  giphyLightGrey,
  giphyLightestGrey,
  giphyPink,
  giphyPurple,
  giphyRed,
  giphyWhite,
  giphyWhiteSmoke,
  giphyYellow,
  instagramColor,
  pinterestColor,
  primaryCTA,
  primaryCTADisabled,
  redditColor,
  secondaryCTA,
  smsColor,
  tumblrColor,
  twitterColor
};
